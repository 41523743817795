<template>
  <div>
    <van-nav-bar
      title="地块列表"
      left-text="返回"
      right-text="添加"
      :fixed="fixed"
      left-arrow
      @click-left="onClickBank"
      @click-right="onClickRight"
      style="margin-bottom: 10px;"
    />
    <div style="margin-top: 57px">
      <div
        class="FarmlandList"
        v-for="(item, index) in farmlandList"
        :key="index"
      >
        <van-swipe-cell>
          <div class="FarmlandList-box">
            <div class="FarmlandList-box-left">
              <div class="FarmlandList-box-left-img">
                <img
                  :src="item.imageUrl"
                  alt=""
                  style="width:100%;height:100%"
                />
              </div>
              <div class="FarmlandList-box-left-word">
                <div class="FarmlandList-box-left-title-name">
                  {{ item.name }}
                </div>
                <div class="location-name">
                  {{ item.province_name }}-{{ item.city_name }}-{{
                    item.county_name
                  }}
                </div>
              </div>
            </div>
            <div class="FarmlandList-box-right">
              <div class="FarmlandList-box-right-word">
                <div class="farmland-type">
                  <!-- {{ item.crop_show }} -->
                </div>
                <div class="farmland-cycle" @click="PlantHistory(item.id)">
                  种植历史
                  <!-- {{ currentCropLifeName }} -->
                </div>
              </div>
              <div class="FarmlandList-box-right-area">{{ item.area }} 亩</div>
            </div>
          </div>
          <template #right>
            <van-button
              square
              type="primary"
              text="编辑"
              @click="editFarmland(item.id)"
              class="farmlandBtn"
            />
            <van-button
              square
              type="danger"
              text="删除"
              @click="deleteFarmland(item.id)"
              class="farmlandBtn"
            />
          </template>
        </van-swipe-cell>
      </div>
    </div>
  </div>
</template>

<script>
import { NavBar, SwipeCell, Button, Dialog, Notify } from "vant";
import { getFarmlandList, postDeleteFarmland } from "@/service/FarmlandList";
export default {
  name: "FarmlandList",
  data() {
    return {
      farmlandList: [],
      currentCropLifeName: "",
      fixed: true,
      srcList: {
        1: require("../../assets/image/crop/crop_mihoutao.png"),
        2: require("../../assets/image/crop/crop_yancao.png")
      },
      land_id: ""
    };
  },
  components: {
    VanNavBar: NavBar,
    VanSwipeCell: SwipeCell,
    VanButton: Button,
    [Dialog.Component.name]: Dialog.Component,
    [Notify.Component.name]: Notify.Component
  },
  mounted() {
    this.getfarmlandData();
  },
  methods: {
    getfarmlandData() {
      getFarmlandList().then(res => {
        const data = res.data;
        this.farmlandList = data;
      });
    },
    onClickBank() {
      this.$router.go(-1);
    },
    onClickRight() {
      this.$router.push({
        path: `/addfarmland`
      });
    },
    PlantHistory(id) {
      this.$router.push({
        path: `/planthistory/${id}`
      });
    },
    editFarmland(id) {
      Dialog.confirm({
        title: "编辑",
        message: "确定编辑"
      })
        .then(() => {
          this.$store.state.farmlandName = ""
          this.$router.push({
            path: `/editfarmland/${id}`
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    deleteFarmland(id) {
      Dialog.confirm({
        title: "删除",
        message: "确定删除"
      })
        .then(() => {
          postDeleteFarmland(id).then(res => {
            if (res.code === 200) {
              Notify({ type: "success", message: "成功删除" });
              this.getfarmlandData();
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.goods-card {
  margin: 0;
  background-color: #fff;
}

.farmlandBtn {
  height: 100%;
}
.FarmlandList {
  margin-top: 10px;
  margin-bottom: 10px;
  .FarmlandList-box {
    display: flex;
    height: 58px;
    .FarmlandList-box-left {
      display: flex;
      .FarmlandList-box-left-img {
        width: 70px;
        height: 60px;
        flex-shrink: 0;
      }
      .FarmlandList-box-left-word {
        margin-top: 5px;
        margin-left: 5px;
        flex-shrink: 0;
        .FarmlandList-box-left-title-name {
          width: 50.4vw;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .location-name {
          margin-top: 16px;
          width: 189px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .FarmlandList-box-right {
      .FarmlandList-box-right-word {
        display: flex;
        .farmland-type {
          position: absolute;
          left: 188px;
          top: 4px;
        }
        .farmland-cycle {
          margin-top: 5px;
          position: absolute;
          right: 14px;
          bottom: 28px;
          background: #001f90;
          color: white;
          font-size: 14px;
          padding: 5px;
        }
      }
      .FarmlandList-box-right-area {
        position: absolute;
        right: 5.33333vw;
        bottom: -0.46667vw;
      }
    }
  }
}
</style>
