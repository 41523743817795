import server from "./axios.config.js";

export const getFarmlandList = function () {
  return server({
    method: "get",
    url: 'http://110.87.103.59:18086/farmfriend/land/selectList'
  })
}

export const postDeleteFarmland = function (id) {
  return server({
    method: "post",
    url: 'http://110.87.103.59:18086/farmfriend/land/delete',
    data: {
      id
    }
  })
}
